import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class ConfirmCard extends Vue {
  @Prop({ type: Number }) private readonly maxWidth?: number;
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;

  private onConfirm() {
    this.$emit("confirm");
  }

  private onCancel() {
    this.$emit("cancel");
  }
}
